export const workExperiences = [
  {
    type: "Full Time",
    company: "insightsoftware",
    role: "Manager - Web development team",
    imageUrl:
      "/insightsoftware.png",
    clickURL:
      "https://insightsoftware.com",
  },
  {
    type: "Full Time",
    company: "Vizlib",
    role: "Lead designer + developer",
    imageUrl:
      "/vizlib.png",
    clickURL:
      "https://wordpress-1206464-4268522.cloudwaysapps.com/",
  },
  {
    type: "Freelance",
    company: "Forest Declaration Dashboard",
    role: "UX/UI designer + Wordpress developer",
    imageUrl:
      "forestdash.png",
    clickURL:
      "https://dashboard.forestdeclaration.org",
  },
  {
    type: "Freelance",
    company: "Bellwether Co",
    role: "UX/UI designer + Wordpress developer",
    imageUrl:
      "bellwether.png",
    clickURL:
      "https://bellwetherco.com/",
  },
  {
    type: "Freelance",
    company: "Climate Focus",
    role: "UX/UI designer + Wordpress developer",
    imageUrl:
      "climatefocus.png",
    clickURL:
      "https://climatefocus.com",
  },
  {
    type: "Freelance",
    company: "Offscript Content",
    role: "UX/UI designer + Wordpress developer",
    imageUrl:
      "offscript.png",
    clickURL:
      "https://offscriptcontent.com",
  },
  {
    type: "Contract",
    company: "Ameego Restaurant Scheduler",
    role: "Lead product designer",
    imageUrl:
      "ameego.png",
    clickURL:
      "https://www.myameego.com",
  },
  {
    type: "Freelance",
    company: "West African Alliance",
    role: "UX/UI designer + Wordpress developer",
    imageUrl:
      "westafrican.png",
    clickURL:
      "https://westafricanalliance.org/",
  },
  {
    type: "Freelance",
    company: "Rem-Fit",
    role: "UX/UI designer",
    imageUrl:
      "remfit.png",
    clickURL:
      "https://www.rem-fit.co.uk",
  },
];

export const heroContent = {
  title: (
    <>
      <span>Designing + developing</span><br/> <span>the web since dial-up</span>
    </>
  ),
  subtitle: "Lead UX/UI Designer + Web Developer",
  logoUrl:
    "/logo.svg",
  liIconUrl:
    "/linkedin.svg",
};
