import logo from './logo.svg';
import './App.css';
import { HeroSection } from './components/HeroSection';
import { heroContent } from './data/data';
import { Portfolio } from './components/Portfolio';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <HeroSection 
          title={heroContent.title}
          subtitle={heroContent.subtitle}
          logoUrl={heroContent.logoUrl}
          liIconUrl={heroContent.liIconUrl}
        />
      </header>
      <Portfolio />

    </div>
  );
}

export default App;
