import React from "react";
import styles from "../styles/Portfolio.module.css";
import { WorkExperienceProps } from "../data/types";
import { useSpring, animated } from "react-spring";

export const WorkExperience: React.FC<WorkExperienceProps> = ({
  type,
  company,
  role,
  imageUrl,
  clickURL,
  index,
}) => {
  const springProps = useSpring({
    to: { opacity: 1, y: 0 },
    from: { opacity: 0, y: 50 },
    config: { 
      duration: 700, 
      easing: (t) => t * t // Example easing function (ease-in)
    },
    delay: index * 150,
  });

  return (
    <animated.div style={springProps} className={styles.experienceCard}>
      <a href={clickURL}>
        <img
          loading="lazy"
          src={imageUrl}
          className={styles.cardImage}
          alt={`${company} project showcase`}
        />
        <div className={styles.employmentType}>{type}</div>
        <h3 className={styles.companyName}>{company}</h3>
        <div className={styles.roleTitle}>{role}</div>
      </a>
    </animated.div>
  );
};