import React from "react";
import styles from "../styles/Portfolio.module.css";
import { HeroSectionProps } from "../data/types";

export const HeroSection: React.FC<HeroSectionProps> = ({
  title,
  subtitle,
  logoUrl,
  liIconUrl,
}) => {
  return (
    <header className={styles.hero}>
      <div className={styles.heroContent}>
        <img
          loading="lazy"
          src={logoUrl}
          className={styles.logo}
          alt="Portfolio logo"
        />
        <div className={styles.ctaWrapper}>
          <a href="mailto:russ@clowcreative.com"><button className={styles.ctaButton}>Hire Me</button></a>
          <a href="https://www.linkedin.com/in/russclow/" target="_blank">
            <img
              loading="lazy"
              src={liIconUrl}
              className={styles.liLogo}
              alt="LinkedIn Logo"
            />
          </a>
        </div>
      </div>
      <h1 className={styles.title}>{title}</h1>
      <h2 className={styles.subtitle}>{subtitle}</h2>
    </header>
  );
};
