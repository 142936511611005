import React from "react";
import styles from "../styles/Portfolio.module.css";
import { WorkExperience } from "./WorkExperience";
import { HeroSection } from "./HeroSection";
import { workExperiences, heroContent } from "../data/data";

export const Portfolio: React.FC = () => {
  return (
    <main className={styles.portfolio}>

      <section className={styles.workGrid}>
        {workExperiences.map((experience, index) => (
          <WorkExperience
            key={`${experience.company}-${index}`}
            {...experience}
            index={index} // Make sure to pass the index prop here
          />
        ))}
      </section>

  
    </main>
  );
};